import React from 'react';
import { AlbumInterface } from "./dataDisco";
import './AlbumComponent.css'

const AlbumComponent = ({album}: { album: AlbumInterface }) => {
    const name = album.name
    const date = album.date
    const image = `/images/${album.image}`
    const tracks = album.tracks
    return (
        <div className={'album'}>
            <div className={'image'}>
                <img src={image} alt=""/>
            </div>
            <div className={'infos'}>
                <div className="title">
                    <div className={'name'}>{name}</div>
                    <div className={'date'}>{date}</div>
                </div>
                <div className="tracks">
                    {tracks.map((track, index) =>
                        <div className="track">{(index+1).toString().padStart(2, '0')}. {track.name}</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AlbumComponent;
