import React from 'react';
import './Content.css'
import { disco } from "./dataDisco"
import AlbumComponent from "./AlbumComponent";

const Content = () => {
    return (
        <div className={'content'}>
            <section>
                <h2>Discography</h2>
                {disco.map(album => (<AlbumComponent album={album} />))}
            </section>
            <section>
                <h2>About Vaalnor</h2>
                <p className="about">Vaalnor is a swiss band from Sion founded in 2009. They evolve in a musical atmosphere that bind pagan metal with some traditional melodic consonance. The band is actually composed of six members: Mickaël (vocals and flute), Dominique and Mathias (guitars), Benjamin (keyboard), Yannick (bass) and Maximilien “Slordef” (drum).</p>
                <p className="about">Vaalnor’s compositions relate a fantastical story, which is a band’s original creation. Through the lyrics, the story follow a man’s epic journey, whom want resolve the tragic circumstances of his brother’s death when he died on the battlefield. After that the hero found a mystical necklace which bind them by magic. The elder brother’s tormented adventure will carry him far away for his native land, in a fantasy and dangerous world.</p>
                <p className="about">From the beginning the band start to play on stage, especially on local places. After many months of practice in November 2009, they record by themselves a three titles demo. In same time they continue to search for gigs.</p>
                <p className="about">Vaalnor released his first studio album “Under the flag of Nayjis” in 2014 after five years of work. Along those five years marked by some events like the Magic Circle Festival where they had the chance to play with some of well-known band. During this period of time the line-up is also modified and the band start to research a new guitarist whom they found as Dominique. He integrate Vaalnor at the release of the first album.</p>
                <p className="about">In 2015 after concert where they played in the first part of Equilibrium, Vaalnor prepare the record of the second studio album.</p>
            </section>
            <section>
                <h2>Gigs and contact</h2>
                <p>For contact and concert proposals, don't hesitate to contact us</p>
                <p>Contact us at <a href="mailto:info@vaalnor.com">info@vaalnor.com</a></p>
            </section>
        </div>
    );
};

export default Content;
