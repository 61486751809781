import React, {useEffect, useState} from 'react';
import './Header.css'

function Header() {
    const [backgroundColor, setbackgroundColor] = useState('')
    const adjust = () => {
        let opacity = window.scrollY / window.innerHeight
        opacity = opacity > 1  ? 1 : opacity
        setbackgroundColor(`rgba(0,0,0,${opacity})`)
    }
    useEffect(() => {
        window.addEventListener('scroll', adjust)
        window.addEventListener('resize', adjust)
        adjust()
    })
    return (
        <header>
            <nav style={{backgroundColor}}>
            </nav>
        </header>
    );
}

export default Header;
