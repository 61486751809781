import React, {useEffect, useState} from 'react';
import "./Logo.css"

const Logo = () => {
        const [height, setHeight] = useState('')
        const adjust = () => {
                let size = window.innerHeight - window.scrollY
                size = size < 80 ? 80 : size
                setHeight(size + 'px')
        }
        useEffect(() => {
                window.addEventListener('scroll', adjust)
                window.addEventListener('resize', adjust)
                adjust()
        })
    return (
        <div className={'logo'} style={{height}}>
            <svg version="1.1" id="Calque_12" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 786 360" enable-background="new 0 0 786 360">
            <path d="M649.5,230c-0.3-0.4-1.6-1.7-2.5-2.6c1.7-2.7,6.7-19.5,5.9-28.5c-0.6-7.4-3.4-22.8-7.4-30.5S635,153.9,629,149
                s-11.3-7-14-8.2c-2.7-1.2-7.9-2.7-14.1-3.7c-1-0.9-6.9-15.3-6.9-15.3c-0.1,5.1-1.2,12.8-4,15c-6.3,0.7-15.5,2.9-19.8,5.4
                c-4.9,2.8-14.7,7.9-22.7,19.2s-11.2,22.9-12,32.1c-0.8,9.2,0.4,17.2,1.5,21.6c1,4.4,3.3,10.3,3.3,10.3s-0.6,2.8-1,3.9
                c-0.4,1-2.8,5-3.9,6.2c-1,1.2-2.6,2.4-2.6,2.4l12.4-2.8l7.1,9.1c4.2,5,9.4,9,15.7,12.1c7.1,3.5,14.5,5.7,22.1,6.4
                c10.1,0.4,19.3-1.2,27.7-5.2c4.6-2.6,8.8-5.2,12.3-7.8c5-4.6,9.3-9.4,12.7-14.4c0.6-0.2,0.9-0.4,2.2-0.4c2.2,0,4.3,0.2,6.5,0.5
                c2.2,0.5,4.2,1.3,6.1,2.4L649.5,230z M583.4,206c-5.3,9.1-12.2,17.8-3.8,40.6c0,0-35.3-16.1-31.7-50.8c1.7-6.2,7.8-10.3,13.1-8.2
                c-0.4-7.4,0.4-11.2,6.8-11.2c6.5,0,8.1,3,5.2,11.2C578.8,187.4,593.6,189.6,583.4,206z M561.6,159.7c0,0,31.2-23.1,59.8-3.2
                c4.6,4.5,5.2,11.8,0.9,15.4c6.7,3.3,9.7,5.8,6.5,11.4c-3.1,5.7-6.6,5.7-12.4-0.9c-2.6,5.2-11.8,17.1-21.1,0.2
                C590,173.6,585.6,163.4,561.6,159.7z M616.8,241.6c-6,2.2-12.9-0.4-14.3-6c-5.9,4.6-9.4,6.1-13.1,0.9c-3.7-5.3-2.2-8.4,6.2-10.7
                c-3.5-4.6-10.2-18,9.1-19c10.5-0.9,21.6-0.3,35.4-20.3C640.1,186.5,647.3,224.7,616.8,241.6z"/>
            <g>
            <path d="M156.3,108.9c0,0,41.5-13.5,52.2-4c11.1,9.9,10.8,27.8-2.7,33.9c-13.5,6-33.1,8.7-31.1-7.8c1.9-16.4,16.9-18.4,21.1-16.1
                c0,0-15.1,4.1-11.2,11.1c3.8,7,11.2,6.1,14.9,0.5c3.7-5.7,8.2-21.7-13.2-19.6c-21.5,2.1-21.5,15.6-24.9,18.5
                C157.8,128.3,156.3,108.9,156.3,108.9"/>
            </g>
            <g>
            <path d="M364.2,40.1c0,0,22.3-15.7,30.7-8.5c5.9,5.1,6.7,15.4,2.4,20.5c-5.8,6.9-18.7,9.1-19.5-1c-0.8-10,7.9-13,10.7-12.1
                c0,0-8.5,4.2-5.4,7.9c3.1,3.7,7.4,2.4,9-1.5c1.6-3.8,2-15.1-10.2-10.1c-10.9,4.5-12.1,11.8-12.7,14C368.3,52,364.2,40.1,364.2,40.1
            "/>
            </g>
            <g>
            <path d="M716.4,268.2c0,0,36.8,8.6,45.1-3.6c8.1-11.9,7.9-28.5-5.3-33.5c-13.2-5-31.8-6.1-28.7,10.1c3.2,16.2,17.4,17,21.2,14.4
            c0,0-14.5-3-11.5-10.2c3-7.2,10.1-7,14-1.6c4,5.4,9.5,21-10.9,20.5c-20.4-0.5-21.5-13.9-25-16.5c-3.5-2.7-3.3,16.8-3.3,16.8"/>
            </g>
            <g>
            <path d="M357.1,48.1c0,0,15.3-31.8,37.8-16.6c-3.6,2.6-3.6,2.6-3.6,2.6s-6.9-1.5-10.5-1.3c-3.5,0.2-8.1,3.7-8.1,3.7L362.7,48
            l-1.7,6.9l-5.3-4"/>
            </g>
            <g>
            <path d="M144.5,147.2c8.4-32-30-22.6-36.7-21.6c2.5-4,21.9-15.9,30-19.4c8.2-3.5,10.7-0.2,11.7,1.7c3.5-4.5,8.2-14.4,8.9-17.4
            c5-32.8-26.1-46.9-40.7-53.9c33.5,6.5,48.4,24.1,53.4,33.3c6.7,13.7-2.5,38-2.5,38s0,0-4.2,7.9c7.2,29.3-2.7,41-6.5,47.7
            c-3.7,6.7-23.3,48.2-28.6,58.4c-5.2,10.2-3,20.6-0.2,29.3c30,16.6,34-10.4,34-10.4l3.5-0.2l30.3,38c-35.3,20.9-63.6,2.5-91.9-8.4
            c-28.3-10.9-49.9,15.4-49.9,15.4c2-7.9,15.6-16.6,30.8-25.1c15.1-8.4,15.9-13.4,16.1-16.6c0.7-10.4-15.2-41.2-15.2-41.2
            C77,205,80,216.9,80,216.9c-9.9-12.9,2.5-23.8,2.5-23.8c-8.2-38.6-25.8-56.6-25.8-56.6c20.1-3,16.4-10.2,16.4-10.2
            C28.4,134.9,45.9,178,45.9,178c-20.4-26.3-3-42.7,0.5-47.4c-14.9,1.5-17.9-10.4-22.3-23.8c2.2,3.7,9.9,11.2,13.4,12.9
            c7.7,4.7,41-14.7,41-14.7s10.4,2.2,13.2,2.7c2.7,0.5,7.9,2.2,11.4,6c-14.4-4.1-12.9,10.9-12.9,20.1c1.5,14.4,17.6,71.5,18.9,76.8
            c0,0,1.6,12.7,13.5-14.2C135.2,167.6,144.5,147.2,144.5,147.2z"/>
            </g>
            <path d="M222.2,138.9c19.8-8.7,9.7-21.7,4.9-27.5c8.2,14.8-8.5,21.9-13.9,25.4c-27.1,13.7-35.8,24.2-41.4,33.2
            c-21.4,37-13.9,57.7-10.5,65c3.3,7.3,0,11.6,0,11.6l1.4,2.6l13.7,24.5l20,4.2c0,0,1.4-14.4-2-17.2c-3.4-2.8-17.5-24.3-19.9-32
            c-2.4-7.8,3.1-5.9,5.2-5.9c16.2,1.9,24.5-2.4,31.3-3.8c6.8-1.4,6.1,7.3,6.1,7.3l0,19.4l6.6,4.6c0,0,15.8-5.9,12-15.1
            c-3.8-9.3-0.9-69.6-0.2-79.7c0.7-10.1,5.6-5.4,5.6-5.4s3.1,2.6,11.1,9.2C246.6,139.8,234,139.4,222.2,138.9z M216.8,151.9
            c0,0-39.9,19.7-5.4,46.3c0,0-17.6-24.8,5.1-32.5c0.5,7.2,0,38.4,0,38.4s-32.5,5.6-43.5,1.8c0.5-12-1.8-43.2,43.7-59.1V151.9z"/>
            <path d="M343.4,222.4c-12.1-2.1-8.7-12.7-8.7-12.7c8.4-68.8-38.9-91.6-51.2-99c-0.5-0.3-0.9-0.4-1-0.5
            c-28.6-18.6-17.4-26.9-17.8-27.6c-15,19.6,5.1,28.1,5.1,28.1c-28.9,1.3-27.4,26.1-27.4,26.1s3.1-5.4,8.4-12.3
            c5.4-6.9,5.4,2.3,5.4,2.3s2,61.1,1.8,77c-0.3,15.9-22.4,31.2-22.4,31.2l5.2,0.5l34.8-13.6c0,0,0.3-11.8,0-23
            c-0.3-11.3,2.3-8.4,3.8-9.2c1.5-0.8,21.5,3.6,30.4,4.3c8.9,0.8,6.9,4.1,6.9,4.1s-2.3,6.6-5.9,14.1c-3.6,7.4-9.2,6.6-9.2,6.6
            l44.2,16.8C345.9,235.7,359,225.2,343.4,222.4z M276.1,176.5c0,0-0.5-31.2,0-38.4c22.8,7.7,5.1,32.5,5.1,32.5
            c34.5-26.6-5.4-46.3-5.4-46.3v-5.1c45.5,15.9,43.2,47,43.7,59.1C308.6,182.1,276.1,176.5,276.1,176.5z"/>
            <g>
            <path d="M334.4,219c0,0,29.1,15.9,32.2-12.9c2.3-25.7,7.3-65.6,7.3-65.6l21.1,4.2c0,0-22.2,98.6,16.9,72.1
            c4.6-8.1,6.1-15.3,4.6-22.6c4.6,9.2,7.7,16.9,5.4,21.9c12.7-13,21.1-20.7,21.9-24.9c0.8-4.2-0.8,16.9-11.9,30.7
            c8.8-0.4,14.2-0.4,15.7-2.7c1.5-2.3,8.1,0.8,8.1,0.8v9.2l-51,5l-50.2-1.5l-16.9-7.7L334.4,219z"/>
            </g>
            <g>
            <path d="M369.7,159.1l-2,13.3c0,0-15.6,9.5-5.1,26.8C362.6,199.2,339.3,174.2,369.7,159.1z"/>
            </g>
            <g>
            <path d="M393.8,165.5c0,0,3.6-20.7,4.1-22.2c-5.4-2-53.7-7.4-56.5-29.9c0.5-8.2-2.6-17.4,25.1-34c0.8-5.1,2.8-11.8-2.3-18.4
            c-1-1.5-8.7-16.4,17.9-27.4c0,0-21.5,12.5-9.2,27.6c28.4,35.5,28.6,50.6,25.6,69.5c-10.2-2.3-22.5-5.9-22.5-5.9s1.8-20.2-6.6-29.1
            c-6.1,3.3-23.8,18.9,5.6,31.4c20.5,5.6,35.3,6.6,38.1,16.4C413.2,145.6,418.6,152.5,393.8,165.5z"/>
            </g>
            <g>
            <path d="M361.3,82.7c0,0-24.8-42.4,20.7-49.1c-11.2,7.2-16.4,15.3-15.3,19.9c1,4.6,7.2,12.3,7.2,12.3l-3.6,17.4L361.3,82.7z"/>
            </g>
            <path d="M679.1,256.5c-4.6-3.1-9.3-4.9-9.3-29.5c0-7.5-3.8-8-9.2-5.1c-3,1.6-6.4,5.8-6.6,9.2c-5.6-3.9,4.7,5.8,6.8,9.3
            c-8-3.6-12.2-4.9-17.1-3.9c-1.1,3-9.2,11.9-15.5,16.5c-18.2,12.2-38.1,15.3-58.9,5.6c-9.1-4.1-18.5-12.3-24.8-21.9
            c-5.7,0.8-11.4,3.1-21.1,3c-2.4,0,8.8-11.9,7-13.7c-3.3-3.3-6.6-6.6-9.8-9.9L424.4,231l-86.4-1.5l-31.2-9.2
            c0,0-28.1-18.9-112.5,40.4l1,18.8c0,0-17.1,24.1-16.8,46.6c12.4-54.2,91.5-83.5,96.7-85c5.2-1.5,8.5,5,8.5,5
            c4.1,5.1,28.7,41.7,39.5,47.8c2.6,2.6,4.6,1.7,5.6-5.8c1.3-4.4,0.5-5.2,7.2-6.7c6.6-1.5,13.3-3.6,22.8-10.7
            c9.5-7.2,14.3-14.3,16.6-16.1c2.3-1.8,20.7-12.5,34.8-0.8c10,10.7,11.8,14.1,18.9,18.2c7.2,4.1,20.5,9.7,22.8,10
            c2.3,0.3,4.3,1.8,5.1,6.1c0.8,4.3-1.5,12.5,6.9,4.1c8.4-8.4,33.2-42.4,37.6-49.3c1-2,3.1-3.7,8.2-1.5c5.1,2.2,71.8,28.6,88.7,84.8
            c-0.5-13.8-6.1-39.4-16.4-48.1c25.6,9.7,28.6,15.3,69.5-1.5c23.5-8.2,32.7-17.4,62.4,6.7C706.1,271.3,683.7,259.6,679.1,256.5z
            M349.5,265.9c-13,7-49,13.7-54.8-23.2c0,0-1.9-4,3.3-4.9c5.2-0.9,19.1-4.6,19.3,0.4c0.2,5-1.3,23,12.6,26.5
            c3.5,0.7,21.1-0.2,19.1-9.6c-1.5-3.5-6.3-10.2-13-4.3c-1.1,1.3-4.3,7.4,9.1,6.1c0,0-22.4,9.1-19.1-10.6c0.4-2.2,9.6-12.2,26.3-2.6
            C354.3,246.2,362.6,259,349.5,265.9z M488.9,242c-5.9,36.8-42.5,30.1-55.8,23.2c-13.3-7-4.9-19.8-2.9-22.2c17-9.6,26.3,0.4,26.8,2.6
            c3.3,19.8-19.5,10.6-19.5,10.6c13.7,1.3,10.4-4.8,9.3-6.1c-6.9-5.9-11.7,0.9-13.3,4.3c-2,9.3,15.9,10.2,19.5,9.6
            c14.2-3.5,12.6-21.5,12.8-26.5c0.2-5,14.4-1.3,19.7-0.4C490.8,238,488.9,242,488.9,242z"/>
            <path d="M719.5,268.8h17.9l-0.5-4.1l-7.2-2.6c-20.5-12.8-8.7-27.1,1-40.4c18.9-23,3.6-46.5-10.2-60.9c16.4-6.1,12.8-23,12.8-23
            c5.1,4.6,8.7,13.3,8.7,13.3c-8.7-53.2-55.7-47-84.9-47c-29.1,0-27.1,7.2-27.1,7.2c4.8-5.1,15.9,2,15.9,2c8.8,12.3-29.7,12.4-43,5.2
            c-44.5-28.6-65.5-10.7-93.1-1.5c-27.6,9.2-5.6,9.7-5.6,9.7c13.8,5.1,9.7,14.3,9.7,38.9c-17.4-31.2-42.4-36.8-42.4-36.8
            c-46-10.7-14.3-43.5-14.3-43.5c-15.3,2.6-16.9,22-16.9,22c-4.1-8.2-1-14.8-1-14.8c-16.4,22,7.2,40.4,7.2,40.4
            C426.5,144,432.6,165,432.6,165c2.6-20.5,19.9-22,22-22c2.6,38.9-7,76.2-7,76.2l8.1,10l47.6,1.3l11.5,3.7l7.5,10.4l1.8-3.1
            c0,0,12.2-3.1,14.8-15.8c-2.5-6.4-3-8.5-4.1-14.5c-5.1-11.5-8.7-25.2-7.9-41c-2-51.1,15.9-48.6,23.5-50.6
            c20.6-1.3,35.5,12.5,38.9,16c0.7-0.8,0.8-0.9,1.3-2.1c0.3-0.3,1.6-8,1.8-9.8s1-8.4,1-8.4s0.7,1.9,1.3,3.5c0.7,2,5.3,15.1,7,17.1
            c9.1,1,16.7,4.8,19.1,6.1l3.2,0.9c33.8-17.9,26.1,20.7,26.1,20.7l-0.3,10.4c0,0,4.3,10.3,4.8,24.6c0.6,16.1-5.8,28.8-5.8,28.8
            l6.6,6.2l6.9,7.6l7.6-14c0,0-0.9-7.8-0.9-30.3c0-22.5,28.1-19.4,40.9-15.9c33.8,6.6-7,51.8-6.6,62.9c-5.6,39.6,43,39.4,43,39.4
            C727.2,282.1,719.5,268.8,719.5,268.8z M463.3,221.9c3.5-19.6,3.8-59.8,3.8-59.8s16.5,25.7,23.8,41.8
            C498.2,220,483.2,217.7,463.3,221.9z M505.5,197.3l-32.2-50.6C508.6,162.8,505.5,197.3,505.5,197.3z M705.8,142.3
            c-16.6,13.1-27.4-1.7-27.4-1.7c1.9,10,7.3,13.4,16.5,18.4c-7.3,9.6-23,7.3-23,7.3s0.2-7.4,1.2-32.6c1-25.1,19.2-21.5,22.3-20.6
            C717.6,122.1,708,140.1,705.8,142.3z"/>
            </svg>
        </div>
    );
};

export default Logo;
