interface TrackInterface {
    name: string
}

export interface AlbumInterface {
    name: string,
    date: string,
    image: string,
    tracks: TrackInterface[]
}

export const disco: AlbumInterface[] = [
    {
        name: 'Under the Flag of Nayjis',
        date: '05.07.2014',
        image: 'Pochette_face.jpg',
        tracks: [
            { name: 'Wall of Death' },
            { name: 'March Towards Destiny' },
            { name: 'Frozen Heart' },
            { name: 'Spirit Is Dead' },
            { name: 'The Death Wounds' },
            { name: 'The Honor of Victory' },
            { name: 'The Summoning' }
        ]
    },
    {
        name: 'Demo',
        date: '01.11.2009',
        image: 'demo.jpg',
        tracks: [
            { name: 'March Towards Destiny' },
            { name: 'Frozen Heart' },
            { name: 'Spirit Is Dead' }
        ]
    }
]
