import React from 'react';
import './App.css';
import Header from "../Header/Header";
import Logo from "../Header/Logo/Logo";
import Content from "../Content/Content";

function App() {
  return (
    <div className="App">
        <Logo />
        <Header />
        <div style={{height: 'calc(100vh)', width: '100%'}} />
        <Content />
    </div>
  );
}

export default App;
